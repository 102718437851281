<template>
  <div>
    <div class="uni-mask" v-if="redeemCodes_dialog" @click="redeemCodes"></div>
    <div class="uni-modal" v-if="redeemCodes_dialog" @click="confirm_laog">
      <div class="uni-modal__hd">
        <strong class="uni-modal__title">任务</strong>
        </div>
          <div class="uni-modal__bd">{{title}}</div>
          <div class="uni-modal__ft">
            <div @click="redeemCodes" class="uni-modal__btn uni-modal__btn_default" style="color: rgb(0, 0, 0);border-top: 1px solid #f4f4f4;border-right: 1px solid #f4f4f4;">
            {{confirm}}
            </div>
            <!-- <div @click="accomplish" class="uni-modal__btn uni-modal__btn_primary" style="color: rgb(0, 122, 255);border-top: 1px solid #f4f4f4;">
              {{confirm}}
            </div> -->
          </div>
        </div>
    </div>
</template>

<style>
.uni-modal__btn{
    display: block;
    -webkit-box-flex: 1;
    flex: 1;
    color: #3cc51f;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    position: relative;
    cursor: pointer;
}
.uni-modal__ft{
    position: relative;
    line-height: 48px;
    font-size: 18px;
    display: flex;
}
.uni-modal__bd{
  padding: 1.3em 1.6em 1.3em;
  font-size: 15px;
  line-height: 1.4;
  color: #999;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.uni-modal__title{
  font-weight: 400;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
}
.uni-modal__hd {
    padding: 1em 1.6em .3em;
}
  .uni-mask {
      position: fixed;
      z-index: 999;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(0,0,0,.5);
  }
  .uni-modal {
    box-sizing: border-box;
    position: fixed;
    z-index: 999;
    width: 80%;
    max-width: 300px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background-color: #fff;
    text-align: center;
    border-radius: 3px;
    overflow: hidden;





    
}
</style>
<script>
import {apiUserCheckClockLogs,apiUpdateUserClockLogs} from "@/request/api";
export default {
  name: 'Confirm',
  data(){
    return {
      redeemCodes_dialog:false,
      title:'',
      confirm:'',
      lang_id:0,
    }
  },
  created(){
    // this.taskStatus = this.$store.state.task_status;
    let lang_id = this.$store.state.lang_id;
    this.lang_id = lang_id;
    if(lang_id == 1){
      this.confirm = this.$zh_ft('confirm');
    }else{
      this.confirm = this.$zh_jt('confirm');
    }
  },
  computed:{
    confirms(){
      return this.$store.state.confirm;
    }
  },
  watch:{
    confirms(val){
      // let task_title = this.$store.state.task_title;
      // let is_done = this.$store.state.is_done;

      // if(!is_done){
      //   if(this.lang_id == 1){
      //     this.title = '要標記完成今天的'+task_title+'嗎？';
      //   }else{
      //     this.title = '要标记完成今天的'+task_title+'吗？';
      //   }
      // }else{
      //   if(this.lang_id == 1){
      //     this.title = '要取消今天的'+task_title+'嗎？';
      //   }else{
      //     this.title = '要取消今天的'+task_title+'吗？';
      //   }
      // }
      if(this.lang_id == 1){
        this.title = '功能尚未開放，敬請期待';
      }else{
        this.title = '功能尚未开发，敬请期待';
      }
      this.redeemCodes_dialog = val;
    }
  },
  methods:{
    redeemCodes(){
      this.$store.state.confirm = false;
      this.redeemCodes_dialog = false
    },
    accomplish(){
      // todayUserClockLog_id 用於判斷今天是否是第一次打卡完成 如果是第一次 則要創建
      let todayUserClockLog_id = this.$store.state.todayUserClockLog_id;
      let clock_id = this.$store.state.clock_id;
      let is_done = this.$store.state.is_done;
      // 該任務還沒有創建打卡任務時
      if(!todayUserClockLog_id || todayUserClockLog_id == 0){
        apiUserCheckClockLogs({
          clock_id:clock_id,
          is_done:is_done?0:1,
        }).then(res=>{
          if(res.code == 200){
            this.$message.success('操作成功');
            location.reload();
          }else{
            this.$message.error(res.message);
            return false;
          }
        }).catch(error=>{
          console.log(error);
        })
      }else{
        // 修改用戶打卡記錄
        apiUpdateUserClockLogs(todayUserClockLog_id,{
          id:todayUserClockLog_id,
          is_done:is_done?0:1,
          lang_id:this.$store.state.lang_id
        }).then(res=>{
          if(res.code == 200){
            this.$message.success('操作成功');
            location.reload();
          }else{
            this.$message.error(res.message);
            return false;
          }
        }).catch(error=>{
          console.log(error);
        })
      }
      this.$store.state.confirm = false;
      this.redeemCodes_dialog = false
    }
  }
}
</script>
