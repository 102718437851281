<template>
  <div>
    <Header/>
    <el-dialog
          :title="task_title"
            v-model="dialogVisible"
            width="90%"
            top="35vh"
            >
            <span class="dialog-footer">
              <el-button @click="update_task">編 輯</el-button>
              <el-button type="primary" @click="delete_task = true">刪 除</el-button>
            </span>
          </el-dialog>
          <el-dialog
            :title="delete_confirm"
            v-model="delete_task"
            top="35vh"
            width="90%">
            <span class="dialog-footer">
              <el-button @click="task_confirm">{{confirm_s}}</el-button>
              <el-button type="primary" @click="delete_task = false">取 消</el-button>
            </span>
          </el-dialog>
    <div class="content_text">
        <!-- <router-link :to="{path:'/'}">
          <img class="content_img_13" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_12" src="../../assets/task/time_icon.png">
        <div calss="content_study">{{task}}</div>
        <div class="rank_div">
          <!-- <router-link :to="{path:'/task/grade_table'}">
          <img class="rank_icon_s" src="../../assets/task/rank_icon.png">
          </router-link> -->
          <img class="rank_icon_s" src="../../assets/task/rank_icon.png">
        </div>
    </div>
    <div class="sv">
      <div class="uni-scroll-view">
          <!-- <div style="padding: 30px; font-size: 20px;color:#fff;">此功能用於團隊成員更良好服務客的活動管理，暫不開放給非團隊成員使用。</div> -->
          <div style="padding: 20px; text-align: center;">
            <div style="margin-bottom: 10px; font-weight: bold;color:#fff;margin: auto;">{{time}}</div>
            <router-link :to="{path:'/task/grade_table'}">
            <!-- <div class="clear-btn round-btn" style="padding: 7px 10px;">查看成績表</div> -->
            </router-link>
          </div>
          <div>
            <div class="lesson-content-title-bar custom">{{a_common_task}}</div>
            <div v-loading="loading">
              <div v-for="item in checkin_item_list" :key="item">
                  <div class="checkin-item" @click="checkin_item(item.clock_type_id,item.id,item.today_user_is_done,item.title,item.todayUserClockLog)">
                    <div style="width: 125px; height: auto;">
                      <img :src="item.image" style="width: 125px;height: 100%;">
                    </div>
                    <div class="centerdiv">
                        <div class="checkin-item-text-con">
                          <p class="pi_1">{{item.title}}</p>
                          <p class="pi_2">{{item.sub_title}}</p>
                        </div>
                    </div>
                    <div class="centerdiv" style="margin-left: auto; margin-right: 15px;">
                      <div class="checkin-item-icons">
                        <img src="../../assets/task/common_task/radio_selected.png" v-if="item.today_user_is_done">
                        <img src="../../assets/task/common_task/radio.png" v-else>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="lesson-content-title-bar custom">
            {{custom_task_s}}
            <span style="font-size: 75%; margin-left: 8px; font-weight: 400;">({{update_or_delete_task}})</span>
          </div>
          <div v-loading="loading">
            <div v-for="item in custom_task" :key="item" :style="{'display':status}" @touchstart="start(item.title,item.id)" @touchend="end">
                <div class="checkin-item" @click="checkin_item(item.clock_type_id,item.id,item.today_user_is_done,item.title,item.todayUserClockLog)" >
                  <div style="width: 35%; height: auto;">
                    <img :src="item.image" style="width: 100%;height: 100%;">
                  </div>
                  <div class="centerdiv" style="width:50%;">
                      <div class="checkin-item-text-con">
                        <p class="pi_1">{{item.title}}</p>
                        <p class="pi_2">{{item.sub_title}}</p>
                      </div>
                  </div>
                  <div class="centerdiv" style="margin-left: auto; margin-right: 15px;">
                    <div class="checkin-item-icons">
                      <img src="../../assets/task/common_task/radio_selected.png" v-if="item.today_user_is_done">
                        <img src="../../assets/task/common_task/radio.png" v-else>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <router-link :to="{path:'/task/add_punch_card_project'}" v-if="user_id">
            <div class="clockin-btn" v-if="status" >{{add_a_punch_in_item}}
              <div class="litpic">
                <img src="../../assets/task/common_task/add_icon.png">
              </div>
            </div>
          </router-link>
          <Confirm  v-if="confirm || confirms"/>
          <div class="blank-bottom"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style>
/* //修改loading加载时的颜色 */
    .el-loading-spinner .path{
      stroke: #fff;
    }
div{
  word-break: break-all;
}
.blank-bottom{
  width: 100%;
  height: 95px;
}
.litpic img{
  width:100%;
  height:100%;
}
.litpic{
 width: 38px;
 height: 38px;
 padding-left: 10px; 
}
.clockin-btn{
  text-align: right;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  border-bottom: 1px solid #fff;
  color:#fff;
}
.lesson-content-title-bar {
    height: 40px;
    background: rgba(0,0,0,.5);
    padding-left: 15px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 700;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.checkin-item-icons img{
  width: 38px;
  height: 38px;
}
.checkin-item-icons{
  display: flex;
  height: 38px
}
.pi_2{
  margin-top: 5px;
  font-family: Helvetica Neue;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin:0;
  color:#fff;
}
.pi_1{
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
  color: #fff;
  margin:0;
}
.checkin-item-text-con{
  padding: 5px 0;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-right: 5px;
    height: -webkit-fit-content;
    height: fit-content;
}
.centerdiv{
  height: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.checkin-item{
  height: auto;
  /* height:75px; */
  min-height: 75px;
  border-bottom: 1px solid #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.custom {
    background: rgba(0,0,0,.4);
    border-bottom: 2px solid #fff;
}
.lesson-content-title-bar {
    height: 40px;
    padding-left: 15px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 700;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    color: #fff;
    text-align: left;
}
.round-btn {
    display: block;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 7px 20px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
    transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.uni-scroll-view{
  width:100%;
  height:100%;
  overflow: hidden auto;
}
.sv{
  height: 72vh;
  /* height:auto; */
}
.rank_div{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
.rank_icon_s{
  height: 40px;
  margin-right: 8px;
  width: 40px;
}
.content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_12{
    width: 50px;
    height: 46px;
    margin-right: 8px;
  }
  .content_img_13{
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
import Footer from "../Footer";
import Confirm from "../Confirm";
import {apiClocks,apiDeleteClocks} from "@/request/api";
export default {
  components:{Header,Footer,Confirm},
  data(){
    return {
      dialogVisible:false,
      delete_task:false,
      status:'none',
      time:'',
      task_title:'',
      task_id:0,
      confirm:true,
      confirms:true,
      taskStatus:false,
      tasksStatus:false,
      loading:false,
      // 判断当前是否是登录状态
      user_id:'',
      checkin_item_list:[
      ],
       //   自訂任務
      custom_task:[
      ],
      task:'',
      a_common_task:'',
      custom_task_s:'',
      update_or_delete_task:'',
      add_a_punch_in_item:'',
      lang_id:0,
      confirm_s:'',
      delete_confirm:'',

    }
  },
  created(){
    let lang_id = this.$store.state.lang_id;
    console.log(lang_id);
    this.lang_id = lang_id;
    if(lang_id == 1){
        this.task = this.$zh_ft('task');
        this.a_common_task = this.$zh_ft('a_common_task');
        this.custom_task_s = this.$zh_ft('custom_task');
        this.update_or_delete_task = this.$zh_ft('update_or_delete_task');
        this.add_a_punch_in_item = this.$zh_ft('add_a_punch_in_item');
        this.confirm_s = this.$zh_ft('confirm');
        this.delete_confirm = this.$zh_ft('delete_confirm');
      }else{
        this.task = this.$zh_jt('task');
        this.a_common_task = this.$zh_jt('a_common_task');
        this.custom_task_s = this.$zh_jt('custom_task');
        this.update_or_delete_task = this.$zh_jt('update_or_delete_task');
        this.add_a_punch_in_item = this.$zh_jt('add_a_punch_in_item');
        this.confirm_s = this.$zh_jt('confirm');
        this.delete_confirm = this.$zh_jt('delete_confirm');
      }

    this.loading = true;
    let user_id = this.$store.state.user_id;
    this.user_id = user_id;
    
    apiClocks({
      lang_id:this.$store.state.lang_id,
      user_id:user_id
    }).then(res=>{
      this.checkin_item_list = res.data.systemList;
      this.custom_task = res.data.userList;
      this.loading = false;
    }).catch(error=>{
      console.log(error);
      this.loading = false;
    })

    let date = new Date();
    let year = date.getFullYear(); // 年
    let month = date.getMonth() + 1; // 月
    let day = date.getDate(); // 日
    if(month < 10){
      month = '0'+month;
    }
    if(day < 10){
      day = '0'+day;
    }
    this.time = year+'-'+month+'-'+day;
    if(this.$store.state.login_verify){
      this.status = 'block';
    }else{
      this.status = 'none';
    }
  },
  methods:{
    // 跳轉任務編輯頁
    update_task(){
      // 任務id
      let task_id = this.task_id;
      this.$router.push({path:'/task/add_punch_card_project/'+task_id});
    },
    // 確定自訂刪除任務
    task_confirm(){
      // 任務id
      let task_id = this.task_id;
      apiDeleteClocks(task_id,{
        user_id:this.user_id
      }).then(res=>{
        if(res.code == 200){
          this.$message.success('刪除成功');
          location.reload();
        }else{
          this.$message.error(res.message);
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    start(task_title,id){
      clearTimeout(this.loop);//再次清空定时器,防止重复注册定时器;
      this.loop=setTimeout(()=>{
        this.dialogVisible = true;
        this.task_id = id;
        if(this.lang_id == 1){
          this.task_title = '任務：'+task_title;
        }else{
          this.task_title = '任务：'+task_title;
        }
      },1000);
    },
    end(){
      clearTimeout(this.loop); //清空定时器，防止重复注册定时器
    },
    /*
      type_id 類型id
      id 任務id
      is_done 是否完成
    */
    checkin_item(type_id,id,is_done,title,todayUserClockLog_id){
      if(!this.user_id){
        this.$router.push({path:'/login'});
        return false;
      }
      // 今日报数
      if(type_id == 1){
        location.href="/count_off_today/"+id;
      }else if(type_id == 2){
        // 簡單勾選
        this.confirm = true;
        this.$store.state.confirm = true;
        this.$store.state.is_done = is_done;
        this.$store.state.task_title = title;
        this.$store.state.todayUserClockLog_id = todayUserClockLog_id[0]?todayUserClockLog_id[0].id:0;
        this.$store.state.clock_id = id;
      }else if(type_id == 3){
        // 圖片+筆記
        this.$router.push({
          path:'/task/add_activation_record/read/'+id
        });
      }else if(type_id == 4){
        // 名单及位置记
        // this.$router.push({
        //   path:'/task/placereport/'+id
        // });
        location.href="/task/placereport/"+id;
      }
    }
  },
  computed:{
      task_status(){
        return this.$store.state.task_status;
      },
      login_verify(){
        return this.$store.state.login_verify;
      }
  },
  watch:{
    task_status(val){
      this.taskStatus = val;
    },
    login_verify(val){
      if(val){
        this.status = 'block';
      }else{
        this.status = 'none';
      }
    }
  },
}
</script>

